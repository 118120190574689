class ZIDXWixEditor implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxWixEditorContainer";
    }
    getMatchURL(){
        return "/wix-editor/";
    }
    render(){
        // console.log("wix editor");

    }
}